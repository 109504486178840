







import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'
import { Component, Vue } from 'vue-property-decorator'
import CatalogSearchBar from './CatalogSearchBar.vue'

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.viewCatalogsTitle
  }), 
  components:{ 
    CatalogSearchBar
  }
})
export default class CatalogTemplate extends Vue {
}
