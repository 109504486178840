
























import FInput from '@/components/Form/FInput.vue'
import FSelect from '@/components/Form/FSelect.vue'
import FForm from '@/components/Form/FForm.vue'
import FButton from '@/components/Button/FButton.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SearchCatalogService } from '@/services/SearchCatalogService'

@Component({
  components:{ 
    FInput,
    FButton,
    FSelect,
    FForm,
  }
})
export default class SongSearchBar extends Vue {
  searched = false;
  search = '';

  mounted() {
    this.search = this.$route.query.s as string || '';
  }

  onSubmit(){ 
    SearchCatalogService.updateSearchText(this.search);
  }

  @Watch('$route')
  onRouteChange(){ 
    this.search = this.$route.query.s as string || '';
  }
}
